
// Define multiple base URLs as an object
const BASE_URLS = {

  // Server link
  main: 'https://b2c.rathin.com/webadmin/api/',
  webUrl: '/',
  // main: 'http://localhost/websites/rahintours/api',
  // webUrl: '/rathintours-react/',
  
  payUrl: 'https://b2c.rathin.com/',
  // payUrl: 'http://localhost/websites/rahintours/',
  pk_live: 'pk_test_j2rvD1P6aMSmVekTlO4zwR8s',
  tapApiUrl: "https://api.tap.company/v2",

};
export default BASE_URLS;
