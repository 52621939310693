import BASE_URLS from "../../baseUrl";

export const PaymentFunctions = () => {
  const mainApiUrl = BASE_URLS.main;
  const payUrl = BASE_URLS.payUrl;

  const tapChargeApi = async (orderPayload) => {
    try {
      const response = await fetch(
        payUrl+`webadmin/api/tap_api_handle.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderPayload,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        return responseData;
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error:", error);
      // setErrorMessage("Failed to check email. Please try again.");
    }
  };

  const insertOrder = async (orderDetails) => {
    try {
      const response = await fetch(
        payUrl+"webadmin/api/insert_update_order_details.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderDetails,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();

        return responseData;
      } else {
        throw new Error("Network response was not ok.");
      }
    } catch (error) {
      console.error("Error:", error);
      // setErrorMessage("Failed to check email. Please try again.");
    }
  };

  return {
    tapChargeApi,
    insertOrder,
  };
};
